interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  }, {
    name: 'Informes',
    url: '/spirit/informes',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Configuración',
    url: '/icons',
    icon: 'icon-star',
    children: [{
      name: 'Usuarios',
      url: '/spirit/usuarios',
      icon: 'icon-puzzle'
    },
    {
      name: 'Terceros',
      url: '/spirit/terceros',
      icon: 'icon-puzzle'
    },
    {
      name: 'Productos',
      url: '/spirit/productos',
      icon: 'icon-puzzle'
    },
    {
      name: 'Estados Despacho',
      url: '/spirit/despachoestados',
      icon: 'icon-puzzle'
    },
    {
      name: 'Instalación impresora',
      url: '/spirit/impresora',
      icon: 'icon-puzzle'
    }
    ]
  },
  {
    name: 'Piezas',
    url: '/spirit/piezas',
    icon: 'icon-puzzle'
  },
  {
    name: 'Despachos',
    url: '/spirit/despachos',
    icon: 'icon-puzzle'
  }

];
