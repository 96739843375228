export const environment = {
    base_Url: 'http://daltex.drmonkey.co/data/',

    base_Url1: 'https://honesolutions.herokuapp.com',
  //base_Url1: 'https://honesolutions-303913.uc.r.appspot.com',
  //base_Url1: 'http://localhost:8080',
    url_Siigo: 'https://private-anon-ce4ffc93af-siigoapi.apiary-proxy.com'
    // base_Url1: 'http://spiritt.appspot.com/'
    //sbase_Url1: 'https://spirit.herokuapp.com'
    // base_Url: 'https://fprestacionalwstst.azurewebsites.net/'
    // export NODE_OPTIONS="--openssl-legacy-provider --no-experimental-fetch"
};
