import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Usuario } from './../model/usuario';
import { Authsiigo } from '../model/authsigo';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const acces_siigo = {

  username: 'nadim.nassar@spiritsas.com',
  access_key: 'MDc4NjNhYmEtNWJiNS00MDk1LWE0MmUtMzQ1ZWRkM2UxMWZkOiU9ODdYYXgyNUc='

};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private LoggedInStatus = false;
  private usuario: Usuario;
  private siigoTocken: Authsiigo;


  constructor(private http: HttpClient) { }
  url = environment.base_Url1;
  url_Siigo = environment.url_Siigo;

  setLoggedIn(value: boolean, usuario: Usuario) {
    this.LoggedInStatus = value;
    this.usuario = usuario;
  }
  setSiigoTocken(siigotocken: Authsiigo) {
    this.siigoTocken = siigotocken;
  }
  get SiigoTocken() {
    return this.siigoTocken.access_token;
  }
  get getId() {
    return this.usuario.IDUsuario;
  }
  get getType() {
    return this.usuario.Tipo;
  }
  get isLoggedIn() {
    return this.LoggedInStatus;
  }


  getUsersDetails(usuario: Usuario) {
    return this.http.post<Usuario>(this.url + '/getauth', JSON.stringify(usuario));
  }
  getTockenSiigo() {
    return this.http.post<Authsiigo>(this.url_Siigo + '/auth', JSON.stringify(acces_siigo), httpOptions);
  }
  registerUserService(username, password, nickname) {

    // tslint:disable-next-line:max-line-length
    return this.http.post<Usuario>(this.url + 'UsuariosQ.php', 'comando=insert&usuario=' + username + '&contrasena=' + password + '&nickname=' + nickname);
  }

}
